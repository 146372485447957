import React, { useEffect } from "react";
import type { AppProps } from "next/app";
import getConfig from "next/config";

import { ContentfulLivePreview } from "@contentful/live-preview";
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";

import Layout from "../components/layout";

import "@JohnLewisPartnership/jlfs-ui-library/dist/styles/globals.scss";
import "@JohnLewisPartnership/jlfs-ui-library/dist/styles/jlp-styles.css";

const { publicRuntimeConfig } = getConfig();

ContentfulLivePreview.init({
  locale: "en-US", // This is required and allows you to set the locale once and have it reused throughout the preview
  enableInspectorMode: false, // This allows you to toggle the inspector mode which is on by default
  enableLiveUpdates: true, // This allows you to toggle the live updates which is on by default
  debugMode: false, // This allows you to toggle the debug mode which is off by default
  targetOrigin: "https://app.contentful.com", // This allows you to configure the allowed host of the live preview (default: ['https://app.contentful.com', 'https://app.eu.contentful.com'])
});

export default function Application({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV !== "development"
    ) {
      // @ts-ignore
      import("@newrelic/browser-agent").then(({ BrowserAgent }) => {
        const options = {
          init: {
            distributed_tracing: { enabled: true },
            privacy: { cookies_enabled: true },
            ajax: { deny_list: ["bam.nr-data.net"] },
          }, // NREUM.init
          info: {
            beacon: "bam.nr-data.net",
            errorBeacon: "bam.nr-data.net",
            licenseKey: publicRuntimeConfig.newRelicLicenseKeyShort,
            applicationID: publicRuntimeConfig.newRelicApplicationID,
            sa: 1,
          }, // NREUM.info
          loader_config: {
            accountID: publicRuntimeConfig.newRelicAccountID,
            trustKey: publicRuntimeConfig.newRelicTrustKey,
            agentID: publicRuntimeConfig.newRelicApplicationID,
            licenseKey: publicRuntimeConfig.newRelicLicenseKeyShort,
            applicationID: publicRuntimeConfig.newRelicApplicationID,
          }, // NREUM.loader_config
        };

        new BrowserAgent(options);
      });
    }
  }, []);

  const updated = useContentfulLiveUpdates(pageProps);
  return (
    <Layout pageProps={updated}>
      <Component {...updated} />
    </Layout>
  );
}
